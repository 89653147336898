<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Fixed Asset Type<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-select
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.type"
                  :items="dropDownCategory"
                  :rules="[v => !!v || 'Type is required']"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Fixed Asset Code</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.code"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Estimated Life<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.estimatedLife"
                  type="number"
                  :rules="[v => v >= 1 || 'Estimated life must more than equal 1']"
                  suffix="Year"
                  @input="onInputYear"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Depreciation Method<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-select
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.depreciationMethod"
                  :items="dropDownMethod"
                  :rules="[v => !!v || 'Depreciation Method is required']"
                  @change="onChangeMethod"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Depreciation Rate<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.depreciationRate"
                  type="number"
                  suffix="%"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader
                  >Depreciation Expense Account<span class="red--text">*</span></v-subheader
                >
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.expenseAccount"
                  item-text="accountName"
                  item-value="accountId"
                  :rules="[v => !!v || 'Account is required']"
                  :items="accounts"
                  :filter="filterObject"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.accountName"></v-list-item-title>
                      <v-list-item-subtitle v-html="data.item.accountNumber"></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader
                  >Accumulated Depreciation Account<span class="red--text">*</span></v-subheader
                >
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.depreciationAccount"
                  item-text="accountName"
                  item-value="accountId"
                  :rules="[v => !!v || 'Account is required']"
                  :items="accounts"
                  :filter="filterObject"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.accountName"></v-list-item-title>
                      <v-list-item-subtitle v-html="data.item.accountNumber"></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Asset Account<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.assetAccount"
                  item-text="accountName"
                  item-value="accountId"
                  :rules="[v => !!v || 'Account is required']"
                  :items="accounts"
                  :filter="filterObject"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.accountName"></v-list-item-title>
                      <v-list-item-subtitle v-html="data.item.accountNumber"></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="1" lg="1" class="py-0">
        <v-row class="py-0">
          <v-col cols="12" class="mb-4 py-0">
            <v-btn color="primary" @click="submit">
              <v-icon large>
                mdi-content-save
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "create-fixed-asset-category",
  data: () => ({
    defaultForm: {
      formNumber: "",
      expenseAccount: null,
      depreciationAccount: null,
      assetAccount: null,
      type: "",
      code: "",
      depreciationMethod: "",
      estimatedLife: 1,
      depreciationRate: 0,
    },
    form: {},
    valid: true,
    accounts: [],
    dropDownMethod: ["Double Declining", "Straight Line"],
    dropDownCategory: [
      "Tanah",
      "Bangunan",
      "Peralatan Pabrik kelompok 1",
      "Peralatan Pabrik kelompok 2",
      "Peralatan Kantor",
      "Komputer",
      "Kendaraan",
    ],
  }),

  computed: {
    ...mapState("fixedAssetCategory", ["lastFormNumber"]),
  },

  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.form.formNumber = this.lastFormNumber;
        this.$store.dispatch("fixedAssetCategory/create", this.form);
        this.clear();
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "All Form is required",
        });
      }
    },
    clear() {
      this.form = Object.assign({}, this.defaultForm);
    },
    onChangeMethod(val) {
      if (val === "Double Declining") {
        this.form.depreciationRate = (100 / this.form.estimatedLife) * 2;
      } else {
        this.form.depreciationRate = 100 / this.form.estimatedLife;
      }
    },
    onInputYear() {
      if (this.form.depreciationMethod === "Double Declining") {
        this.form.depreciationRate = (100 / this.form.estimatedLife) * 2;
      } else {
        this.form.depreciationRate = 100 / this.form.estimatedLife;
      }
    },
    // eslint-disable-next-line no-unused-vars
    filterObject(item, queryText, itemText) {
      return (
        item.accountName.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.accountNumber.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    async getAccounts() {
      const response = await this.$store.dispatch("chartOfAccount/getAccounts");
      if (response.status === 200) {
        this.accounts = response.data;
      }
    },
  },

  mounted() {
    this.getAccounts();
    this.$store.dispatch("fixedAssetCategory/getLastFormNumber");
    this.form = Object.assign({}, this.defaultForm);
  },
};
</script>

<style></style>
